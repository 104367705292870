<template lang="pug">
.productPage
  div.productPage__writeReview
    h2 Write a review
    br
    .editorMode(v-if="$store.getters.userLoggedIn && editorMode")
      div.editorMode--editor
        | Rank this product from 1 to 10: &nbsp
        RankInput(v-model="newReview.rank")
        textarea(
          v-model="newReview.content"
          placeholder="Please enter your review here"
        )
      div.photoUploaderContainer
        PhotoUploader(v-model="newReview['pictures']")
      div
        button.productPage__writeReview__add(@click="addReview()" :disabled="newReview.content === ''")
          span(v-if="!loading") Submit
          i(v-else class="fal fa-spinner")
        button.productPage__writeReview__cancel(@click="cancelReview()")
          | Cancel
    button.productPage__writeReview--editorButton(
      v-else-if="!editorMode && $store.getters.userLoggedIn"
      @click="editorMode = true"
    )
      i(class="fal fa-plus") &nbsp
      span Add review
    p(v-else) Please
      button.signIn(@click.stop="$emit('openMagicSidebar')") sign in
      | to continue.
  h3(style="text-align: left") Reviews ({{ reviews.length }}):
    div.productPage__reviews(v-for="(review, index) in reviews" :key="index")
      div
        div.productPage__reviews--heading--title
          img.productPage__reviews--heading--avatar(:src="review.owner.image")
          h4 {{ review.owner.name }} &nbsp
        p Rating: {{ review.rank }}
          i(class="fas fa-star")
        p(:class="`${readMore ? 'content content--full' : 'content'}`") {{ review.content }}
        span.readMore(v-if="readMore !== true" @click="readMore = true") Read More
      img.productPage__reviews--heading--image(v-if="review.pictures[0]" :src="review.pictures[0]")
</template>

<script>
import httpClient from '@/services/httpClient'
import { reject } from 'q'

export default {
  props: {
    productData: {
      type: Object,
      required: true
    }
  },
  components: {
    RankInput: () => import('@/components/RankInput.vue'),
    httpClient,
    PhotoUploader: () => import('@/components/PhotoUploader.vue')
  },
  data () {
    return {
      loading: false,
      editorMode: false,
      reviews: [],
      newReview: {
        post_type: 'review',
        pictures: [],
        content: '',
        rank: 1
      },
      readMore: false
    }
  },
  computed: {
    slug () {
      return this.$route.params.slug
    }
  },
  created () {
    this.getReviews()
  },
  methods: {
    getReviews () {
      httpClient.get(`/item/${this.productData.id}/posts?format=full&type=review&sort_by=desc(rank)`).then(response => {
        this.reviews = response.data
      })
    },
    cancelReview () {
      this.newReview = {}
      this.editorMode = false
    },
    addReview () {
      this.loading = true
      this.newReview.rank = parseInt(this.newReview.rank, 10)
      httpClient.post(`/item/${this.productData.id}/post`, this.newReview)
        .then(response => {
          this.loading = false
          this.editorMode = false
          this.getReviews()
        })
        .catch(error => {
          this.editorMode = false
          this.loading = false
          alert('Oops! Something went wrong, please try again.')
          reject(error)
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
h2
  font-family: 'Open Sans', Helvetica, Arial, sans-serif
  font-weight normal
h3
  font-family: 'Open Sans', Helvetica, Arial, sans-serif
  font-weight normal
h4
  font-family: 'Open Sans', Helvetica, Arial, sans-serif
  font-weight normal
.signIn
  text-decoration underline
  background transparent
  border none
  cursor pointer
.productPage
  &__writeReview
    font-size 14px
    text-align left
    border-bottom 1px solid #ddd
    padding-bottom 20px
    input
      position relative
      border none
      background #fff
      border 1px solid rgba(0, 0, 0, .1)
      padding 10px
      margin-bottom 20px
      font inherit
      transition border-color 0.3s
      &:focus
        border-color dodgerblue
    textarea
      width 100%
      min-height 100px
      position relative
      border none
      background #fff
      border 1px solid rgba(0, 0, 0, .1)
      padding 10px
      margin-bottom 10px
      font inherit
      transition border-color 0.3s
      &:focus
        border-color dodgerblue
    &--editorButton
      border none
      width auto
      border-radius 2px
      color white
      font inherit
      background-color black
      padding 5px 16px
      box-shadow 0 0 6px rgba(0, 0, 0, .13)
      cursor pointer
      margin 10px 0
      &[disabled]
        opacity .4
        cursor default
        &:hover
          opacity .4
    &__add
      border none
      width auto
      border-radius 2px
      color white
      font inherit
      background-color dodgerblue
      padding 5px 16px
      box-shadow 0 0 6px rgba(0, 0, 0, .13)
      cursor pointer
      margin 10px 0
      &[disabled]
        opacity .4
        cursor default
        &:hover
          opacity .4
    &__cancel
      border none
      width auto
      color black
      text-decoration underline
      font inherit
      background transparent
      padding 5px 16px
      cursor pointer
      margin 10px
      &[disabled]
        opacity .4
        cursor default
        &:hover
          opacity .4
  &__reviews
    display grid
    font-size 14px
    height auto
    max-width 100%
    grid-template auto / auto 300px
    text-align left
    border-bottom 1px solid #ddd
    margin-bottom 20px
    padding 20px 0
    i
      color #ffd700
      margin 11px 5px
    &--heading
      display flex
      &--title
        display flex
        flex-direction row
        h3
          width 100%
          margin 10px 0
        h4
          margin 10px 0
      &--image
        position relative
        vertical-align middle
        width auto
        max-height 150px
        float left
      &--avatar
        position relative
        vertical-align middle
        height 50px
        border-radius 50%
        width 50px
        margin-right 10px
.editorMode
  display flex
  font-size 14px
  justify-content space-between
  flex-direction row
  flex-wrap wrap
  .photoUploaderContainer
    margin-top 55px
  &--editor
    min-width 60%
  div
    margin 0 20px
    margin-left 0
.content
  font-size 14px
  text-align left
  font-size 14px
  max-height 200px
  width 100%
  overflow-y hidden
  &--full
    max-height 100%
.readMore
  font-size 14px
  float left
  color dodgerblue
  cursor pointer

@media screen and (max-width: 1024px)
  .editorMode
    flex-direction column
    div
      margin 0
@media screen and (max-width: 780px)
  .productPage
    &__reviews
      grid-template auto / auto
      &--heading
        &__image
          max-height 150px
          max-width 100%
        &--title
          flex-direction column
@media screen and (max-width: 550px)
  .productPage
    &__tab
      &--label
        display none
      &--icon
        display block
    &__reviews
      &--heading
        flex-direction column-reverse
        &--image
          margin-right 0
          max-width 100%
        &--title
          flex-direction row
@media screen and (max-width: 435px)
  .productPage
    &__reviews
      grid-template auto / auto
      max-width 200px
      &--heading
        &__image
          max-height 150px
          max-width 200px
</style>
